.vjs-theme-memoriamtv {
  --vjs-theme-memoriamtv--primary: #9C1680;
  --vjs-theme-memoriamtv--secondary: #5CC2EF;
}

.vjs-theme-memoriamtv .vjs-big-play-button {
  width: 88px;
  height: 88px;
  background: none;
  line-height: 88px;
  font-size: 80px;
  border: none;
  top: 50%;
  left: 50%;
  margin-top: -44px;
  margin-left: -44px;
  background-color: var(--vjs-theme-memoriamtv--primary);
  background-color: #00000066;
  color: #fff;
  border-radius: 1em;
}

.vjs-theme-memoriamtv:hover .vjs-big-play-button,
.vjs-theme-memoriamtv.vjs-big-play-button:focus {
  background-color: var(--vjs-theme-memoriamtv--primary);
}

.vjs-theme-memoriamtv .vjs-control-bar {
  height: 54px;
}
.vjs-theme-memoriamtv .vjs-button > .vjs-icon-placeholder::before {
  line-height: 54px;
}

.vjs-theme-memoriamtv .vjs-time-control {
  line-height: 54px;
}

/* Play Button */
.vjs-theme-memoriamtv .vjs-play-control {
  font-size: 1.5em;
  position: relative;
}

.vjs-theme-memoriamtv .vjs-volume-panel {
  order: 4;
}

.vjs-theme-memoriamtv .vjs-volume-bar {
  margin-top: 2.5em;
}

.vjs-theme-city .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
  height: 100%;
}

.vjs-theme-memoriamtv .vjs-progress-control .vjs-progress-holder {
  font-size: 1.5em;
}

.vjs-theme-memoriamtv .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.5em;
}

.vjs-theme-memoriamtv .vjs-play-control .vjs-icon-placeholder::before {
  height: 1.3em;
  width: 1.3em;
  margin-top: 0.2em;
  border-radius: 1em;
  border: 3px solid var(--vjs-theme-memoriamtv--secondary);
  top: 2px;
  left: 9px;
  line-height: 1.1;
}

.vjs-theme-memoriamtv .vjs-play-control:hover .vjs-icon-placeholder::before {
  border: 3px solid var(--vjs-theme-memoriamtv--secondary);
}

.vjs-theme-memoriamtv .vjs-play-progress {
  background-color: var(--vjs-theme-memoriamtv--primary);
}

.vjs-theme-memoriamtv .vjs-play-progress::before {
  height: 0.8em;
  width: 0.8em;
  content: '';
  background-color: var(--vjs-theme-memoriamtv--primary);
  border: 4px solid var(--vjs-theme-memoriamtv--secondary);
  border-radius: 0.8em;
  top: -0.25em;
}

.vjs-theme-memoriamtv .vjs-progress-control {
  font-size: 14px;
}

.vjs-theme-memoriamtv .vjs-fullscreen-control {
  order: 6;
}

.vjs-theme-memoriamtv .vjs-remaining-time {
  display: none;
}

.vjs-theme-memoriamtv .vjs-seek-to-live-control {
  line-height: 5.5em;
}

.vjs-theme-memoriamtv .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  bottom: 3.9em;
}
