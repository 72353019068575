* {
  font-family: 'Roboto', sans-serif;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  margin: 0px;
}
h1, h2, h3, h4, h5 {
  text-align: center;
}
.footer--wrapper {
  background: #F1F1F1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
}
footer > p {
  margin-bottom: 0px;
  padding: 15px;
}
h2 {
  font-size: 3em;
  margin-block-start: 15px;
  margin-block-end: 15px;
}
td .description {
  vertical-align: top;
}
.text-center {
  text-align: center;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.testButton {
  margin-block-start: 15px;
  margin-block-end: 15px;
}

@media only screen and (max-width: 576px) {
  h2 {
    font-size: 2em;
    margin-block-start: 5px;
    margin-block-end: 5px;
  }
  .footer--wrapper {
    height: 30px; /* Set the fixed height of the footer here */
    line-height: 20px; /* Vertically center the text there */
  }
  footer > p {
    margin-bottom: 0px;
    padding: 5px;
  }
}


/* xs Default */
/* sm Small devices (tablets, 768px and up) */
/* md Medium devices (desktops, 992px and up) */
/* lg Large devices (large desktops, 1120 and up) */
@media only screen and (max-width: 360px) {
  .is-embedded {
    font-size: 12px;
  }
  .is-embedded h3 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 361px) and (max-width: 410px) {
  .is-embedded {
    font-size: 14px;
  }
  .is-embedded h3 {
    font-size: 1.1rem;
  }
}
