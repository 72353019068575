.spinner {
  width:  20px;
  height: 20px;
  position: relative;
  margin: 10px auto;
}

.spinner .double-bounce1,
.spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: purple;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.spinner.inverted .double-bounce1,
.spinner.inverted .double-bounce2 {
  background-color: black;
}
.spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* xs Default */
/* sm Small devices (tablets, 768px and up) */
/* md Medium devices (desktops, 992px and up) */
/* lg Large devices (large desktops, 1120 and up) */
@media only screen and (min-width: 576px) {
  .spinner {
    width:  30px;
    height: 30px;
    margin: 50px auto;
  }
}
@media only screen and (min-width: 768px) {
  .spinner {
    width:  40px;
    height: 40px;
    margin: 100px auto;
  }
}
@media only screen and (min-width: 1120px) {
  .spinner {
    width:  40px;
    height: 40px;
    margin: 140px auto;
  }
}
