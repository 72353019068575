.header--wrapper {
  background: #F1F1F1;
  min-height: 5rem;
}

img.logo {
  height: 5rem;
}
@media only screen and (max-width: 576px) {
  .header--wrapper {
    min-height: 4rem;
  }
  img.logo {
    height: 4rem;
  }
}
